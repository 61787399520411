import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import aboutImage from '../images/croppedImage.png';

import Layout from '../components/layout';
import SEO from '../components/seo';

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO site={data.site} title="Engineering Manager Skills Tree" />
      
        <h2 className="about-header">What is this?</h2>
        <div className="about-content">
          <div className="about-text">
            <p>This is the list that I constantly update of <b>skills</b> that I gather as Engineering Manager. I hope it may be an useful guideline of tools usable in your own career!
             Please be aware that I've used multiple sources like <a href="https://roadmap.sh/">https://roadmap.sh/</a> to build this, and of
             course my own knowledge. <b>Enjoy!</b> 😃</p>
          </div>
        </div>
        <div>
            <iframe
                width="100%"
                className="skills-iframe"
                height="1000"
                scrolling="no"
                src="https://ioctopus.online/sh-3748e397488bba02?menubar=0&panelMain=0&panelThemes=0&panelIcons=0&panelColors=0"
            ></iframe>
        </div>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
        metaTitle
      }
    }
  }
`;
